// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/iam/v1/service/sso/sso.proto (package iam.v1.sso, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { SSOClaimRoleMapping, SSOConfig, SSOProvier } from "../../types/types_pb";
/**
 * @generated from message iam.v1.sso.SetupSSORequest
 */
export class SetupSSORequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: map<string, iam.v1.types.SSOClaimRoleMapping> claim_mappings = 2;
         */
        this.claimMappings = {};
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new SetupSSORequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SetupSSORequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SetupSSORequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(SetupSSORequest, a, b);
    }
}
SetupSSORequest.runtime = proto3;
SetupSSORequest.typeName = "iam.v1.sso.SetupSSORequest";
SetupSSORequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "sso_config", kind: "message", T: SSOConfig },
    { no: 2, name: "claim_mappings", kind: "map", K: 9 /* ScalarType.STRING */, V: { kind: "message", T: SSOClaimRoleMapping } },
]);
/**
 * @generated from message iam.v1.sso.SetupSSOResponse
 */
export class SetupSSOResponse extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new SetupSSOResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SetupSSOResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SetupSSOResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(SetupSSOResponse, a, b);
    }
}
SetupSSOResponse.runtime = proto3;
SetupSSOResponse.typeName = "iam.v1.sso.SetupSSOResponse";
SetupSSOResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "sso_provider", kind: "message", T: SSOProvier },
]);
/**
 * @generated from message iam.v1.sso.UpdateSSORequest
 */
export class UpdateSSORequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: map<string, iam.v1.types.SSOClaimRoleMapping> claim_mappings = 2;
         */
        this.claimMappings = {};
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new UpdateSSORequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UpdateSSORequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UpdateSSORequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(UpdateSSORequest, a, b);
    }
}
UpdateSSORequest.runtime = proto3;
UpdateSSORequest.typeName = "iam.v1.sso.UpdateSSORequest";
UpdateSSORequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "sso_config", kind: "message", T: SSOConfig },
    { no: 2, name: "claim_mappings", kind: "map", K: 9 /* ScalarType.STRING */, V: { kind: "message", T: SSOClaimRoleMapping } },
]);
/**
 * @generated from message iam.v1.sso.UpdateSSOResponse
 */
export class UpdateSSOResponse extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new UpdateSSOResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UpdateSSOResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UpdateSSOResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(UpdateSSOResponse, a, b);
    }
}
UpdateSSOResponse.runtime = proto3;
UpdateSSOResponse.typeName = "iam.v1.sso.UpdateSSOResponse";
UpdateSSOResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "sso_provider", kind: "message", T: SSOProvier },
]);
/**
 * @generated from message iam.v1.sso.DeleteSSORequest
 */
export class DeleteSSORequest extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new DeleteSSORequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DeleteSSORequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DeleteSSORequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(DeleteSSORequest, a, b);
    }
}
DeleteSSORequest.runtime = proto3;
DeleteSSORequest.typeName = "iam.v1.sso.DeleteSSORequest";
DeleteSSORequest.fields = proto3.util.newFieldList(() => []);
/**
 * @generated from message iam.v1.sso.GetSSORequest
 */
export class GetSSORequest extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new GetSSORequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GetSSORequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GetSSORequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(GetSSORequest, a, b);
    }
}
GetSSORequest.runtime = proto3;
GetSSORequest.typeName = "iam.v1.sso.GetSSORequest";
GetSSORequest.fields = proto3.util.newFieldList(() => []);
/**
 * @generated from message iam.v1.sso.GetSSOResponse
 */
export class GetSSOResponse extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new GetSSOResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GetSSOResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GetSSOResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(GetSSOResponse, a, b);
    }
}
GetSSOResponse.runtime = proto3;
GetSSOResponse.typeName = "iam.v1.sso.GetSSOResponse";
GetSSOResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "sso_provider", kind: "message", T: SSOProvier },
]);
/**
 * @generated from message iam.v1.sso.SSOLoginRequest
 */
export class SSOLoginRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string account_alias = 1;
         */
        this.accountAlias = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new SSOLoginRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SSOLoginRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SSOLoginRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(SSOLoginRequest, a, b);
    }
}
SSOLoginRequest.runtime = proto3;
SSOLoginRequest.typeName = "iam.v1.sso.SSOLoginRequest";
SSOLoginRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "account_alias", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
/**
 * @generated from message iam.v1.sso.SSOLoginResponse
 */
export class SSOLoginResponse extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string url = 1;
         */
        this.url = "";
        /**
         * @generated from field: string type = 2;
         */
        this.type = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new SSOLoginResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SSOLoginResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SSOLoginResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(SSOLoginResponse, a, b);
    }
}
SSOLoginResponse.runtime = proto3;
SSOLoginResponse.typeName = "iam.v1.sso.SSOLoginResponse";
SSOLoginResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
/**
 * @generated from message iam.v1.sso.SSOCallBackRequest
 */
export class SSOCallBackRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string state = 1;
         */
        this.state = "";
        /**
         * @generated from field: string code = 2;
         */
        this.code = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new SSOCallBackRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SSOCallBackRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SSOCallBackRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(SSOCallBackRequest, a, b);
    }
}
SSOCallBackRequest.runtime = proto3;
SSOCallBackRequest.typeName = "iam.v1.sso.SSOCallBackRequest";
SSOCallBackRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
/**
 * @generated from message iam.v1.sso.SSOCallBackResponse
 */
export class SSOCallBackResponse extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string auth_token = 1;
         */
        this.authToken = "";
        /**
         * @generated from field: repeated string role_dris = 2;
         */
        this.roleDris = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new SSOCallBackResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SSOCallBackResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SSOCallBackResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(SSOCallBackResponse, a, b);
    }
}
SSOCallBackResponse.runtime = proto3;
SSOCallBackResponse.typeName = "iam.v1.sso.SSOCallBackResponse";
SSOCallBackResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "auth_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "role_dris", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
]);
