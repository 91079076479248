var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * JWT Interceptor
 * Adds the JWT token to the Authorization header.
 */
export const jwtInterceptor = (token) => {
    return (next) => (req) => __awaiter(void 0, void 0, void 0, function* () {
        if (!token) {
            throw new Error('No JWT token provided, redirecting to login');
        }
        req.header.set('Authorization', `Bearer ${token}`);
        return next(req);
    });
};
/**
 * Access Key & Secret Key Interceptor
 * Adds a signature to the Authorization header.
 * The signature generation logic should match your backend requirements.
 */
export const keyInterceptor = (accessKey, secretKey) => {
    return (next) => (req) => __awaiter(void 0, void 0, void 0, function* () {
        if (!accessKey || !secretKey) {
            throw new Error('Access key or secret key missing, redirecting to login');
        }
        // Example: Generate a simple HMAC SHA256 signature
        // Adjust the signature generation as per your backend's requirements
        const crypto = window.crypto || window.msCrypto;
        const encoder = new TextEncoder();
        const data = encoder.encode(req.method + JSON.stringify(req.contextValues));
        const key = yield crypto.subtle.importKey('raw', encoder.encode(secretKey), { name: 'HMAC', hash: 'SHA-256' }, false, ['sign']);
        const signature = yield crypto.subtle.sign('HMAC', key, data);
        const signatureHex = Array.from(new Uint8Array(signature))
            .map(b => b.toString(16).padStart(2, '0'))
            .join('');
        req.header.set('Authorization', `AccessKey ${accessKey}:Signature ${signatureHex}`);
        return next(req);
    });
};
/**
 * Unauthorized Error Handler Interceptor
 * Redirects to login on 401 Unauthorized errors.
 */
export const unauthorizedInterceptor = (next) => (req) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        return yield next(req);
    }
    catch (error) {
        if (error.code === 'Unauthenticated' || error.statusCode === 401) {
            throw new Error('Not authenticated');
        }
        throw error;
    }
});
