var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createConnectTransport } from "@connectrpc/connect-web";
import { createAliasServiceClient, createGroupServiceClient, createAccessKeyServiceClient, createPolicyServiceClient, createUserServiceClient, EmptyCredentialProvider, JwtCredentialProvider, createUsageServiceClient, createSsoServiceClient } from "@/ds-apis";
// Function to check token validity (can be extended to check expiration or make an API call)
const getToken = () => {
    const token = localStorage.getItem("authToken"); // Retrieve token from localStorage
    return token;
};
// Redirect function to login page
const redirectToLogin = () => {
    window.location.href = "/signin"; // Redirect to your login route
};
const authMiddleware = (next) => (req) => __awaiter(void 0, void 0, void 0, function* () {
    const token = getToken();
    // If no token, redirect to login
    if (!token) {
        // redirectToLogin();
        return Promise.reject(new Error("No valid token, redirecting to login"));
    }
    // Set the Authorization header
    req.header.set("Authorization", `Bearer ${token}`);
    try {
        // Continue with the request
        return yield next(req);
    }
    catch (error) {
        // Check if error is 401 Unauthorized
        if (error.code === "Unauthenticated" || error.statusCode === 401) {
            redirectToLogin(); // Redirect if unauthorized
        }
        throw error; // Re-throw other errors
    }
});
const transport = createConnectTransport({
    baseUrl: "https://demo.connectrpc.com",
    interceptors: [
        authMiddleware
    ]
});
const config = {
    region: "",
    service: "",
    endpoint: "https://iam.us.api.dev.defencestation.ca/",
    ssl: false,
};
const clientCredentials = new JwtCredentialProvider({
    token: getToken() || '',
});
// Here we make the client itself, combining the service
// definition with the transport.
export const userClient = createUserServiceClient(config, clientCredentials, [authMiddleware]);
const emptyCredentials = new EmptyCredentialProvider();
// Here we make the client itself, combining the service
// definition with the transport.
export const authClient = createUserServiceClient(config, emptyCredentials);
// Here we make the client itself, combining the service
// definition with the transport.
export const iamConnectClient = createUsageServiceClient(config, clientCredentials, [authMiddleware]);
export const aliasClient = createAliasServiceClient(config, clientCredentials, [authMiddleware]);
export const keyClient = createAccessKeyServiceClient(config, clientCredentials, [authMiddleware]);
export const policyClient = createPolicyServiceClient(config, clientCredentials, [authMiddleware]);
export const groupClient = createGroupServiceClient(config, clientCredentials, [authMiddleware]);
export const ssoClient = createSsoServiceClient(config, clientCredentials);
