var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createConnectTransport } from "@connectrpc/connect-web";
import { unauthorizedInterceptor } from './auth/interceptors';
import { resolveEndpoint } from "./config/EndpointResolver";
/**
 * Creates a ConnectRPC transport with optional authentication using credential providers.
 * @param config - Client configuration including service, region, etc.
 * @param credentialProvider - Credential provider for authentication.
 * @param additionalInterceptors - Optional additional interceptors provided by the user.
 * @returns Configured ConnectRPC transport.
 */
export const createAuthorizedTransport = (config, credentialProvider, additionalInterceptors) => {
    // Resolve the service endpoint based on the configuration
    const serviceUrl = resolveEndpoint(config);
    // Define an interceptor to inject authentication headers
    const authInterceptor = (next) => (req) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            // Retrieve authentication headers from the credential provider
            const authHeaders = yield credentialProvider.getAuthHeaders(req.message);
            // Inject each header into the request
            for (const [key, value] of Object.entries(authHeaders)) {
                if (value) {
                    req.header.set(key, value);
                }
            }
        }
        catch (error) {
            console.error("Failed to retrieve auth headers:", error);
            throw error;
        }
        // Proceed to the next interceptor or send the request
        return next(req);
    });
    // Initialize the list of interceptors
    const interceptors = [
        authInterceptor, // Authentication interceptor
        unauthorizedInterceptor, // Handle unauthorized errors
    ];
    // Append any additional user-provided interceptors
    if (additionalInterceptors && additionalInterceptors.length > 0) {
        interceptors.push(...additionalInterceptors);
    }
    const transportOptions = {
        baseUrl: serviceUrl,
        interceptors,
    };
    // const transportOptionsNode: ConnectTransportOptionsNode  = {
    //     baseUrl: serviceUrl,
    //     httpVersion: "1.1",
    //     interceptors,
    // }
    // Create and return the configured ConnectRPC transport
    return createConnectTransport(transportOptions);
    // typeof window === "undefined" ? 
    // createConnectTransportNode(transportOptionsNode) 
    // {}
    // : 
    // createConnectTransport(transportOptions);
};
