// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/iam/v1/service/usage/usage.proto (package iam.v1.usage, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message iam.v1.usage.GetUsageRequest
 */
export class GetUsageRequest extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new GetUsageRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GetUsageRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GetUsageRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(GetUsageRequest, a, b);
    }
}
GetUsageRequest.runtime = proto3;
GetUsageRequest.typeName = "iam.v1.usage.GetUsageRequest";
GetUsageRequest.fields = proto3.util.newFieldList(() => []);
/**
 * @generated from message iam.v1.usage.GetUsageResponse
 */
export class GetUsageResponse extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: int32 users_count = 1;
         */
        this.usersCount = 0;
        /**
         * @generated from field: int32 groups_count = 2;
         */
        this.groupsCount = 0;
        /**
         * @generated from field: int32 policy_count = 3;
         */
        this.policyCount = 0;
        /**
         * @generated from field: int32 keys_count = 4;
         */
        this.keysCount = 0;
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new GetUsageResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GetUsageResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GetUsageResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(GetUsageResponse, a, b);
    }
}
GetUsageResponse.runtime = proto3;
GetUsageResponse.typeName = "iam.v1.usage.GetUsageResponse";
GetUsageResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "users_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "groups_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "policy_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "keys_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
]);
