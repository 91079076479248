// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts,import_extension=none"
// @generated from file station/iam/v1/service/usage/usage.proto (package iam.v1.usage, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { GetUsageRequest, GetUsageResponse } from "./usage_pb";
import { MethodKind } from "@bufbuild/protobuf";
/**
 * @generated from service iam.v1.usage.UsageService
 */
export const UsageService = {
    typeName: "iam.v1.usage.UsageService",
    methods: {
        /**
         * @generated from rpc iam.v1.usage.UsageService.GetUsage
         */
        getUsage: {
            name: "GetUsage",
            I: GetUsageRequest,
            O: GetUsageResponse,
            kind: MethodKind.Unary,
        },
    }
};
