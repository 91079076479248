// At the top of the file
import { createPromiseClient } from "@connectrpc/connect";
import { createAuthorizedTransport } from "../../../../../src/transport";
import { AliasService } from "./alias_connect";
/**
 * Factory function to create a 'alias' client.
 *
 * @param {string} baseUrl - The base URL for 'alias'.
 * @param {Array<Function>} additionalInterceptors - Optional additional interceptors.
 * @returns {PromiseClient} The configured 'alias' client.
 */
export const createAliasServiceClient = (config, credentialProvider, additionalInterceptors = []) => {
    const transport = createAuthorizedTransport(config, credentialProvider, additionalInterceptors);
    return createPromiseClient(AliasService, transport);
};
