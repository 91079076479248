// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/iam/v1/service/user/user.proto (package iam.v1.user, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { AccountType, Group, Policy, User, UserAccessType } from "../../types/types_pb";
/**
 * @generated from message iam.v1.user.CreateRootUserRequest
 */
export class CreateRootUserRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string first_name = 1;
         */
        this.firstName = "";
        /**
         * @generated from field: string last_name = 2;
         */
        this.lastName = "";
        /**
         * @generated from field: iam.v1.types.AccountType account_type = 3;
         */
        this.accountType = AccountType.UNSPECIFIED;
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new CreateRootUserRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new CreateRootUserRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new CreateRootUserRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(CreateRootUserRequest, a, b);
    }
}
CreateRootUserRequest.runtime = proto3;
CreateRootUserRequest.typeName = "iam.v1.user.CreateRootUserRequest";
CreateRootUserRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "account_type", kind: "enum", T: proto3.getEnumType(AccountType) },
]);
/**
 * @generated from message iam.v1.user.CreateRootUserResponse
 */
export class CreateRootUserResponse extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string auth_token = 2;
         */
        this.authToken = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new CreateRootUserResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new CreateRootUserResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new CreateRootUserResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(CreateRootUserResponse, a, b);
    }
}
CreateRootUserResponse.runtime = proto3;
CreateRootUserResponse.typeName = "iam.v1.user.CreateRootUserResponse";
CreateRootUserResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: User },
    { no: 2, name: "auth_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
/**
 * @generated from message iam.v1.user.CreateUserRequest
 */
export class CreateUserRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string username = 1;
         */
        this.username = "";
        /**
         * @generated from field: string email = 2;
         */
        this.email = "";
        /**
         * @generated from field: string first_name = 3;
         */
        this.firstName = "";
        /**
         * @generated from field: string last_name = 4;
         */
        this.lastName = "";
        /**
         * @generated from field: iam.v1.types.UserAccessType access_type = 5;
         */
        this.accessType = UserAccessType.MANAGEMENT_UNSPECIFIED;
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new CreateUserRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new CreateUserRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new CreateUserRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(CreateUserRequest, a, b);
    }
}
CreateUserRequest.runtime = proto3;
CreateUserRequest.typeName = "iam.v1.user.CreateUserRequest";
CreateUserRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "access_type", kind: "enum", T: proto3.getEnumType(UserAccessType) },
]);
/**
 * @generated from message iam.v1.user.CreateUserResponse
 */
export class CreateUserResponse extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new CreateUserResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new CreateUserResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new CreateUserResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(CreateUserResponse, a, b);
    }
}
CreateUserResponse.runtime = proto3;
CreateUserResponse.typeName = "iam.v1.user.CreateUserResponse";
CreateUserResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: User },
]);
/**
 * @generated from message iam.v1.user.GetUserRequest
 */
export class GetUserRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string user_dri = 1;
         */
        this.userDri = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new GetUserRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GetUserRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GetUserRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(GetUserRequest, a, b);
    }
}
GetUserRequest.runtime = proto3;
GetUserRequest.typeName = "iam.v1.user.GetUserRequest";
GetUserRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "user_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
/**
 * @generated from message iam.v1.user.GetUserResponse
 */
export class GetUserResponse extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new GetUserResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GetUserResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GetUserResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(GetUserResponse, a, b);
    }
}
GetUserResponse.runtime = proto3;
GetUserResponse.typeName = "iam.v1.user.GetUserResponse";
GetUserResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: User },
]);
/**
 * @generated from message iam.v1.user.ListUsersRequest
 */
export class ListUsersRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string search = 1;
         */
        this.search = "";
        /**
         * @generated from field: int32 page_size = 2;
         */
        this.pageSize = 0;
        /**
         * @generated from field: int32 page_number = 3;
         */
        this.pageNumber = 0;
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new ListUsersRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ListUsersRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ListUsersRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(ListUsersRequest, a, b);
    }
}
ListUsersRequest.runtime = proto3;
ListUsersRequest.typeName = "iam.v1.user.ListUsersRequest";
ListUsersRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "search", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
]);
/**
 * @generated from message iam.v1.user.ListUsersResponse
 */
export class ListUsersResponse extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: repeated iam.v1.types.User users = 1;
         */
        this.users = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new ListUsersResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ListUsersResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ListUsersResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(ListUsersResponse, a, b);
    }
}
ListUsersResponse.runtime = proto3;
ListUsersResponse.typeName = "iam.v1.user.ListUsersResponse";
ListUsersResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "users", kind: "message", T: User, repeated: true },
]);
/**
 * @generated from message iam.v1.user.UpdateUserRequest
 */
export class UpdateUserRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string user_dri = 1;
         */
        this.userDri = "";
        /**
         * @generated from field: string first_name = 2;
         */
        this.firstName = "";
        /**
         * @generated from field: string last_name = 3;
         */
        this.lastName = "";
        /**
         * @generated from field: iam.v1.types.UserAccessType access_type = 4;
         */
        this.accessType = UserAccessType.MANAGEMENT_UNSPECIFIED;
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new UpdateUserRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UpdateUserRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UpdateUserRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(UpdateUserRequest, a, b);
    }
}
UpdateUserRequest.runtime = proto3;
UpdateUserRequest.typeName = "iam.v1.user.UpdateUserRequest";
UpdateUserRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "user_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "access_type", kind: "enum", T: proto3.getEnumType(UserAccessType) },
]);
/**
 * @generated from message iam.v1.user.UpdateUserResponse
 */
export class UpdateUserResponse extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new UpdateUserResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UpdateUserResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UpdateUserResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(UpdateUserResponse, a, b);
    }
}
UpdateUserResponse.runtime = proto3;
UpdateUserResponse.typeName = "iam.v1.user.UpdateUserResponse";
UpdateUserResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: User },
]);
/**
 * @generated from message iam.v1.user.DeleteUsersRequest
 */
export class DeleteUsersRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: repeated string user_dris = 1;
         */
        this.userDris = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new DeleteUsersRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DeleteUsersRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DeleteUsersRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(DeleteUsersRequest, a, b);
    }
}
DeleteUsersRequest.runtime = proto3;
DeleteUsersRequest.typeName = "iam.v1.user.DeleteUsersRequest";
DeleteUsersRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "user_dris", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
]);
/**
 * @generated from message iam.v1.user.RequestOTPRequest
 */
export class RequestOTPRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string email = 1;
         */
        this.email = "";
        /**
         * @generated from field: string account_id = 2;
         */
        this.accountId = "";
        /**
         * @generated from field: string username = 3;
         */
        this.username = "";
        /**
         * @generated from field: string recaptcha_code = 4;
         */
        this.recaptchaCode = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new RequestOTPRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new RequestOTPRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new RequestOTPRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(RequestOTPRequest, a, b);
    }
}
RequestOTPRequest.runtime = proto3;
RequestOTPRequest.typeName = "iam.v1.user.RequestOTPRequest";
RequestOTPRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "recaptcha_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
/**
 * @generated from message iam.v1.user.RequestOTPResponse
 */
export class RequestOTPResponse extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string device_token = 1;
         */
        this.deviceToken = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new RequestOTPResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new RequestOTPResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new RequestOTPResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(RequestOTPResponse, a, b);
    }
}
RequestOTPResponse.runtime = proto3;
RequestOTPResponse.typeName = "iam.v1.user.RequestOTPResponse";
RequestOTPResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "device_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
/**
 * @generated from message iam.v1.user.GetAuthTokenRequest
 */
export class GetAuthTokenRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string email = 1;
         */
        this.email = "";
        /**
         * @generated from field: string account_id = 2;
         */
        this.accountId = "";
        /**
         * @generated from field: string username = 3;
         */
        this.username = "";
        /**
         * @generated from field: string otp = 4;
         */
        this.otp = "";
        /**
         * @generated from field: string device_token = 5;
         */
        this.deviceToken = "";
        /**
         * @generated from field: string recaptcha_code = 6;
         */
        this.recaptchaCode = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new GetAuthTokenRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GetAuthTokenRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GetAuthTokenRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(GetAuthTokenRequest, a, b);
    }
}
GetAuthTokenRequest.runtime = proto3;
GetAuthTokenRequest.typeName = "iam.v1.user.GetAuthTokenRequest";
GetAuthTokenRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "otp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "device_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "recaptcha_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
/**
 * @generated from message iam.v1.user.GetAuthTokenResponse
 */
export class GetAuthTokenResponse extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string auth_token = 1;
         */
        this.authToken = "";
        /**
         * @generated from field: bool sign_up = 2;
         */
        this.signUp = false;
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new GetAuthTokenResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GetAuthTokenResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GetAuthTokenResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(GetAuthTokenResponse, a, b);
    }
}
GetAuthTokenResponse.runtime = proto3;
GetAuthTokenResponse.typeName = "iam.v1.user.GetAuthTokenResponse";
GetAuthTokenResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "auth_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sign_up", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
]);
/**
 * @generated from message iam.v1.user.GetAuthInfoRequest
 */
export class GetAuthInfoRequest extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new GetAuthInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GetAuthInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GetAuthInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(GetAuthInfoRequest, a, b);
    }
}
GetAuthInfoRequest.runtime = proto3;
GetAuthInfoRequest.typeName = "iam.v1.user.GetAuthInfoRequest";
GetAuthInfoRequest.fields = proto3.util.newFieldList(() => []);
/**
 * @generated from message iam.v1.user.GetAuthInfoResponse
 */
export class GetAuthInfoResponse extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new GetAuthInfoResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GetAuthInfoResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GetAuthInfoResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(GetAuthInfoResponse, a, b);
    }
}
GetAuthInfoResponse.runtime = proto3;
GetAuthInfoResponse.typeName = "iam.v1.user.GetAuthInfoResponse";
GetAuthInfoResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: User },
]);
/**
 * @generated from message iam.v1.user.AcceptInviteRequest
 */
export class AcceptInviteRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string auth_token = 1;
         */
        this.authToken = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new AcceptInviteRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AcceptInviteRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AcceptInviteRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(AcceptInviteRequest, a, b);
    }
}
AcceptInviteRequest.runtime = proto3;
AcceptInviteRequest.typeName = "iam.v1.user.AcceptInviteRequest";
AcceptInviteRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "auth_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
/**
 * @generated from message iam.v1.user.AcceptInviteResponse
 */
export class AcceptInviteResponse extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string status = 1;
         */
        this.status = "";
        /**
         * @generated from field: string auth_token = 2;
         */
        this.authToken = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new AcceptInviteResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AcceptInviteResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AcceptInviteResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(AcceptInviteResponse, a, b);
    }
}
AcceptInviteResponse.runtime = proto3;
AcceptInviteResponse.typeName = "iam.v1.user.AcceptInviteResponse";
AcceptInviteResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "auth_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
/**
 * @generated from message iam.v1.user.ListUserPoliciesRequest
 */
export class ListUserPoliciesRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string user_dri = 1;
         */
        this.userDri = "";
        /**
         * @generated from field: int32 page_size = 2;
         */
        this.pageSize = 0;
        /**
         * @generated from field: int32 page_number = 3;
         */
        this.pageNumber = 0;
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new ListUserPoliciesRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ListUserPoliciesRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ListUserPoliciesRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(ListUserPoliciesRequest, a, b);
    }
}
ListUserPoliciesRequest.runtime = proto3;
ListUserPoliciesRequest.typeName = "iam.v1.user.ListUserPoliciesRequest";
ListUserPoliciesRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "user_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
]);
/**
 * @generated from message iam.v1.user.ListUserPoliciesResponse
 */
export class ListUserPoliciesResponse extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: repeated iam.v1.types.Policy policies = 1;
         */
        this.policies = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new ListUserPoliciesResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ListUserPoliciesResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ListUserPoliciesResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(ListUserPoliciesResponse, a, b);
    }
}
ListUserPoliciesResponse.runtime = proto3;
ListUserPoliciesResponse.typeName = "iam.v1.user.ListUserPoliciesResponse";
ListUserPoliciesResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "policies", kind: "message", T: Policy, repeated: true },
]);
/**
 * @generated from message iam.v1.user.ListUserGroupsRequest
 */
export class ListUserGroupsRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string user_dri = 1;
         */
        this.userDri = "";
        /**
         * @generated from field: int32 page_size = 2;
         */
        this.pageSize = 0;
        /**
         * @generated from field: int32 page_number = 3;
         */
        this.pageNumber = 0;
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new ListUserGroupsRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ListUserGroupsRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ListUserGroupsRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(ListUserGroupsRequest, a, b);
    }
}
ListUserGroupsRequest.runtime = proto3;
ListUserGroupsRequest.typeName = "iam.v1.user.ListUserGroupsRequest";
ListUserGroupsRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "user_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
]);
/**
 * @generated from message iam.v1.user.ListUserGroupsResponse
 */
export class ListUserGroupsResponse extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: repeated iam.v1.types.Group groups = 1;
         */
        this.groups = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new ListUserGroupsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ListUserGroupsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ListUserGroupsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(ListUserGroupsResponse, a, b);
    }
}
ListUserGroupsResponse.runtime = proto3;
ListUserGroupsResponse.typeName = "iam.v1.user.ListUserGroupsResponse";
ListUserGroupsResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "groups", kind: "message", T: Group, repeated: true },
]);
