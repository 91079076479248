// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/iam/v1/types/types.proto (package iam.v1.types, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from enum iam.v1.types.AccountType
 */
export var AccountType;
(function (AccountType) {
    /**
     * @generated from enum value: ACCOUNT_TYPE_UNSPECIFIED = 0;
     */
    AccountType[AccountType["UNSPECIFIED"] = 0] = "UNSPECIFIED";
    /**
     * @generated from enum value: ACCOUNT_TYPE_PERSONAL = 1;
     */
    AccountType[AccountType["PERSONAL"] = 1] = "PERSONAL";
    /**
     * @generated from enum value: ACCOUNT_TYPE_BUSINESS = 2;
     */
    AccountType[AccountType["BUSINESS"] = 2] = "BUSINESS";
})(AccountType || (AccountType = {}));
// Retrieve enum metadata with: proto3.getEnumType(AccountType)
proto3.util.setEnumType(AccountType, "iam.v1.types.AccountType", [
    { no: 0, name: "ACCOUNT_TYPE_UNSPECIFIED" },
    { no: 1, name: "ACCOUNT_TYPE_PERSONAL" },
    { no: 2, name: "ACCOUNT_TYPE_BUSINESS" },
]);
/**
 * @generated from enum iam.v1.types.GroupType
 */
export var GroupType;
(function (GroupType) {
    /**
     * @generated from enum value: GROUP_TYPE_UNSPECIFIED = 0;
     */
    GroupType[GroupType["UNSPECIFIED"] = 0] = "UNSPECIFIED";
})(GroupType || (GroupType = {}));
// Retrieve enum metadata with: proto3.getEnumType(GroupType)
proto3.util.setEnumType(GroupType, "iam.v1.types.GroupType", [
    { no: 0, name: "GROUP_TYPE_UNSPECIFIED" },
]);
/**
 * @generated from enum iam.v1.types.UserAccessType
 */
export var UserAccessType;
(function (UserAccessType) {
    /**
     * @generated from enum value: USER_ACCESS_TYPE_MANAGEMENT_UNSPECIFIED = 0;
     */
    UserAccessType[UserAccessType["MANAGEMENT_UNSPECIFIED"] = 0] = "MANAGEMENT_UNSPECIFIED";
    /**
     * @generated from enum value: USER_ACCESS_TYPE_PROGRAMATIC = 1;
     */
    UserAccessType[UserAccessType["PROGRAMATIC"] = 1] = "PROGRAMATIC";
})(UserAccessType || (UserAccessType = {}));
// Retrieve enum metadata with: proto3.getEnumType(UserAccessType)
proto3.util.setEnumType(UserAccessType, "iam.v1.types.UserAccessType", [
    { no: 0, name: "USER_ACCESS_TYPE_MANAGEMENT_UNSPECIFIED" },
    { no: 1, name: "USER_ACCESS_TYPE_PROGRAMATIC" },
]);
/**
 * @generated from message iam.v1.types.Role
 */
export class Role extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string dri = 1;
         */
        this.dri = "";
        /**
         * @generated from field: string account_id = 2;
         */
        this.accountId = "";
        /**
         * @generated from field: string role_name = 3;
         */
        this.roleName = "";
        /**
         * @generated from field: string description = 4;
         */
        this.description = "";
        /**
         * @generated from field: repeated iam.v1.types.RoleTrustStatement trust_statements = 5;
         */
        this.trustStatements = [];
        /**
         * @generated from field: string created_at = 6;
         */
        this.createdAt = "";
        /**
         * @generated from field: string updated_at = 7;
         */
        this.updatedAt = "";
        /**
         * @generated from field: map<string, string> metadata = 8;
         */
        this.metadata = {};
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new Role().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Role().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Role().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Role, a, b);
    }
}
Role.runtime = proto3;
Role.typeName = "iam.v1.types.Role";
Role.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "role_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "trust_statements", kind: "message", T: RoleTrustStatement, repeated: true },
    { no: 6, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: { kind: "scalar", T: 9 /* ScalarType.STRING */ } },
]);
/**
 * Defines a policy statement.
 *
 * @generated from message iam.v1.types.RoleTrustStatement
 */
export class RoleTrustStatement extends Message {
    constructor(data) {
        super();
        /**
         * Statement ID
         *
         * @generated from field: string name = 1;
         */
        this.name = "";
        /**
         * @generated from field: string effect = 2;
         */
        this.effect = "";
        /**
         * @generated from field: repeated string actions = 4;
         */
        this.actions = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new RoleTrustStatement().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new RoleTrustStatement().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new RoleTrustStatement().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(RoleTrustStatement, a, b);
    }
}
RoleTrustStatement.runtime = proto3;
RoleTrustStatement.typeName = "iam.v1.types.RoleTrustStatement";
RoleTrustStatement.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "effect", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "principal", kind: "message", T: Principal },
    { no: 4, name: "actions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "conditions", kind: "message", T: Conditions },
]);
/**
 * @generated from message iam.v1.types.SSOProvier
 */
export class SSOProvier extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string dri = 1;
         */
        this.dri = "";
        /**
         * @generated from field: string account_id = 2;
         */
        this.accountId = "";
        /**
         * @generated from field: map<string, iam.v1.types.SSOClaimRoleMapping> claim_mappings = 4;
         */
        this.claimMappings = {};
        /**
         * @generated from field: string created_at = 5;
         */
        this.createdAt = "";
        /**
         * @generated from field: string updated_at = 6;
         */
        this.updatedAt = "";
        /**
         * @generated from field: map<string, string> metadata = 7;
         */
        this.metadata = {};
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new SSOProvier().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SSOProvier().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SSOProvier().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(SSOProvier, a, b);
    }
}
SSOProvier.runtime = proto3;
SSOProvier.typeName = "iam.v1.types.SSOProvier";
SSOProvier.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "sso_config", kind: "message", T: SSOConfig },
    { no: 4, name: "claim_mappings", kind: "map", K: 9 /* ScalarType.STRING */, V: { kind: "message", T: SSOClaimRoleMapping } },
    { no: 5, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: { kind: "scalar", T: 9 /* ScalarType.STRING */ } },
]);
/**
 * @generated from message iam.v1.types.SSOConfig
 */
export class SSOConfig extends Message {
    constructor(data) {
        super();
        /**
         * @generated from oneof iam.v1.types.SSOConfig.config
         */
        this.config = { case: undefined };
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new SSOConfig().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SSOConfig().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SSOConfig().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(SSOConfig, a, b);
    }
}
SSOConfig.runtime = proto3;
SSOConfig.typeName = "iam.v1.types.SSOConfig";
SSOConfig.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "google_sso", kind: "message", T: GoogleSSO, oneof: "config" },
    { no: 2, name: "okta_sso", kind: "message", T: OktaSSO, oneof: "config" },
    { no: 3, name: "azuread_sso", kind: "message", T: AzureADSSO, oneof: "config" },
]);
/**
 * @generated from message iam.v1.types.SSOClaimRoleMapping
 */
export class SSOClaimRoleMapping extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string claim_name = 1;
         */
        this.claimName = "";
        /**
         * @generated from field: repeated string values = 2;
         */
        this.values = [];
        /**
         * @generated from field: string role_dri = 3;
         */
        this.roleDri = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new SSOClaimRoleMapping().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SSOClaimRoleMapping().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SSOClaimRoleMapping().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(SSOClaimRoleMapping, a, b);
    }
}
SSOClaimRoleMapping.runtime = proto3;
SSOClaimRoleMapping.typeName = "iam.v1.types.SSOClaimRoleMapping";
SSOClaimRoleMapping.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "claim_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "role_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
/**
 * @generated from message iam.v1.types.GoogleSSO
 */
export class GoogleSSO extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string client_id = 1;
         */
        this.clientId = "";
        /**
         * @generated from field: string client_secret = 2;
         */
        this.clientSecret = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new GoogleSSO().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoogleSSO().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoogleSSO().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(GoogleSSO, a, b);
    }
}
GoogleSSO.runtime = proto3;
GoogleSSO.typeName = "iam.v1.types.GoogleSSO";
GoogleSSO.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "client_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "client_secret", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
/**
 * @generated from message iam.v1.types.OktaSSO
 */
export class OktaSSO extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string client_id = 1;
         */
        this.clientId = "";
        /**
         * @generated from field: string client_secret = 2;
         */
        this.clientSecret = "";
        /**
         * @generated from field: string okta_domain = 3;
         */
        this.oktaDomain = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new OktaSSO().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new OktaSSO().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new OktaSSO().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(OktaSSO, a, b);
    }
}
OktaSSO.runtime = proto3;
OktaSSO.typeName = "iam.v1.types.OktaSSO";
OktaSSO.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "client_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "client_secret", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "okta_domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
/**
 * @generated from message iam.v1.types.AzureADSSO
 */
export class AzureADSSO extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string client_id = 1;
         */
        this.clientId = "";
        /**
         * @generated from field: string client_secret = 2;
         */
        this.clientSecret = "";
        /**
         * @generated from field: string tenant_id = 3;
         */
        this.tenantId = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new AzureADSSO().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AzureADSSO().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AzureADSSO().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(AzureADSSO, a, b);
    }
}
AzureADSSO.runtime = proto3;
AzureADSSO.typeName = "iam.v1.types.AzureADSSO";
AzureADSSO.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "client_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "client_secret", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "tenant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
/**
 * @generated from message iam.v1.types.Account
 */
export class Account extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string dri = 1;
         */
        this.dri = "";
        /**
         * @generated from field: string alias = 2;
         */
        this.alias = "";
        /**
         * @generated from field: string account_id = 3;
         */
        this.accountId = "";
        /**
         * @generated from field: string root_user = 4;
         */
        this.rootUser = "";
        /**
         * @generated from field: map<string, string> metadata = 5;
         */
        this.metadata = {};
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new Account().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Account().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Account().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Account, a, b);
    }
}
Account.runtime = proto3;
Account.typeName = "iam.v1.types.Account";
Account.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "alias", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "root_user", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: { kind: "scalar", T: 9 /* ScalarType.STRING */ } },
]);
/**
 * @generated from message iam.v1.types.Group
 */
export class Group extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string dri = 1;
         */
        this.dri = "";
        /**
         * @generated from field: string group_name = 2;
         */
        this.groupName = "";
        /**
         * @generated from field: string description = 3;
         */
        this.description = "";
        /**
         * @generated from field: iam.v1.types.GroupType group_type = 4;
         */
        this.groupType = GroupType.UNSPECIFIED;
        /**
         * @generated from field: string created_at = 5;
         */
        this.createdAt = "";
        /**
         * @generated from field: string updated_at = 6;
         */
        this.updatedAt = "";
        /**
         * @generated from field: map<string, string> metadata = 7;
         */
        this.metadata = {};
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new Group().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Group().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Group().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Group, a, b);
    }
}
Group.runtime = proto3;
Group.typeName = "iam.v1.types.Group";
Group.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "group_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "group_type", kind: "enum", T: proto3.getEnumType(GroupType) },
    { no: 5, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: { kind: "scalar", T: 9 /* ScalarType.STRING */ } },
]);
/**
 * @generated from message iam.v1.types.User
 */
export class User extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string dri = 1;
         */
        this.dri = "";
        /**
         * @generated from field: string account_id = 2;
         */
        this.accountId = "";
        /**
         * @generated from field: string username = 3;
         */
        this.username = "";
        /**
         * @generated from field: string email = 4;
         */
        this.email = "";
        /**
         * @generated from field: string first_name = 5;
         */
        this.firstName = "";
        /**
         * @generated from field: string last_name = 6;
         */
        this.lastName = "";
        /**
         * @generated from field: iam.v1.types.AccountType account_type = 7;
         */
        this.accountType = AccountType.UNSPECIFIED;
        /**
         * @generated from field: string user_type = 8;
         */
        this.userType = "";
        /**
         * @generated from field: iam.v1.types.UserAccessType access_type = 9;
         */
        this.accessType = UserAccessType.MANAGEMENT_UNSPECIFIED;
        /**
         * @generated from field: string created_at = 10;
         */
        this.createdAt = "";
        /**
         * @generated from field: string updated_at = 11;
         */
        this.updatedAt = "";
        /**
         * @generated from field: map<string, string> metadata = 12;
         */
        this.metadata = {};
        /**
         * @generated from field: bool email_verified = 13;
         */
        this.emailVerified = false;
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new User().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new User().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new User().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(User, a, b);
    }
}
User.runtime = proto3;
User.typeName = "iam.v1.types.User";
User.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "account_type", kind: "enum", T: proto3.getEnumType(AccountType) },
    { no: 8, name: "user_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "access_type", kind: "enum", T: proto3.getEnumType(UserAccessType) },
    { no: 10, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: { kind: "scalar", T: 9 /* ScalarType.STRING */ } },
    { no: 13, name: "email_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
]);
/**
 * @generated from message iam.v1.types.AccessKey
 */
export class AccessKey extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string dri = 1;
         */
        this.dri = "";
        /**
         * @generated from field: string account_id = 2;
         */
        this.accountId = "";
        /**
         * @generated from field: string access_key = 3;
         */
        this.accessKey = "";
        /**
         * @generated from field: string secret_key = 4;
         */
        this.secretKey = "";
        /**
         * @generated from field: bool active = 5;
         */
        this.active = false;
        /**
         * @generated from field: string created_at = 6;
         */
        this.createdAt = "";
        /**
         * @generated from field: string updated_at = 7;
         */
        this.updatedAt = "";
        /**
         * @generated from field: map<string, string> metadata = 8;
         */
        this.metadata = {};
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new AccessKey().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AccessKey().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AccessKey().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(AccessKey, a, b);
    }
}
AccessKey.runtime = proto3;
AccessKey.typeName = "iam.v1.types.AccessKey";
AccessKey.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "access_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "secret_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: { kind: "scalar", T: 9 /* ScalarType.STRING */ } },
]);
/**
 * @generated from message iam.v1.types.Alias
 */
export class Alias extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string dri = 1;
         */
        this.dri = "";
        /**
         * @generated from field: string account_id = 2;
         */
        this.accountId = "";
        /**
         * @generated from field: string alias = 3;
         */
        this.alias = "";
        /**
         * @generated from field: string created_at = 4;
         */
        this.createdAt = "";
        /**
         * @generated from field: string updated_at = 5;
         */
        this.updatedAt = "";
        /**
         * @generated from field: map<string, string> metadata = 6;
         */
        this.metadata = {};
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new Alias().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Alias().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Alias().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Alias, a, b);
    }
}
Alias.runtime = proto3;
Alias.typeName = "iam.v1.types.Alias";
Alias.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "alias", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: { kind: "scalar", T: 9 /* ScalarType.STRING */ } },
]);
/**
 * policy messages
 *
 * @generated from message iam.v1.types.Principal
 */
export class Principal extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string kind = 1;
         */
        this.kind = "";
        /**
         * @generated from field: repeated string values = 2;
         */
        this.values = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new Principal().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Principal().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Principal().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Principal, a, b);
    }
}
Principal.runtime = proto3;
Principal.typeName = "iam.v1.types.Principal";
Principal.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "kind", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
]);
/**
 * @generated from message iam.v1.types.StringCondition
 */
export class StringCondition extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string key = 1;
         */
        this.key = "";
        /**
         * @generated from field: repeated string values = 2;
         */
        this.values = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new StringCondition().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new StringCondition().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new StringCondition().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(StringCondition, a, b);
    }
}
StringCondition.runtime = proto3;
StringCondition.typeName = "iam.v1.types.StringCondition";
StringCondition.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
]);
/**
 * @generated from message iam.v1.types.Int32Condition
 */
export class Int32Condition extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string key = 1;
         */
        this.key = "";
        /**
         * @generated from field: repeated int32 values = 2;
         */
        this.values = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new Int32Condition().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Int32Condition().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Int32Condition().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Int32Condition, a, b);
    }
}
Int32Condition.runtime = proto3;
Int32Condition.typeName = "iam.v1.types.Int32Condition";
Int32Condition.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "values", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
]);
/**
 * @generated from message iam.v1.types.BoolCondition
 */
export class BoolCondition extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string key = 1;
         */
        this.key = "";
        /**
         * @generated from field: bool value = 2;
         */
        this.value = false;
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new BoolCondition().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new BoolCondition().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new BoolCondition().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(BoolCondition, a, b);
    }
}
BoolCondition.runtime = proto3;
BoolCondition.typeName = "iam.v1.types.BoolCondition";
BoolCondition.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
]);
/**
 * @generated from message iam.v1.types.Conditions
 */
export class Conditions extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: repeated iam.v1.types.StringCondition string_equals = 1;
         */
        this.stringEquals = [];
        /**
         * @generated from field: repeated iam.v1.types.StringCondition string_not_equals = 2;
         */
        this.stringNotEquals = [];
        /**
         * @generated from field: repeated iam.v1.types.StringCondition string_like = 3;
         */
        this.stringLike = [];
        /**
         * @generated from field: repeated iam.v1.types.Int32Condition numeric_equals = 4;
         */
        this.numericEquals = [];
        /**
         * @generated from field: repeated iam.v1.types.Int32Condition numeric_not_equals = 5;
         */
        this.numericNotEquals = [];
        /**
         * @generated from field: repeated iam.v1.types.Int32Condition numeric_less_than = 6;
         */
        this.numericLessThan = [];
        /**
         * @generated from field: repeated iam.v1.types.Int32Condition numeric_greater_than = 7;
         */
        this.numericGreaterThan = [];
        /**
         * @generated from field: repeated iam.v1.types.Int32Condition numeric_less_than_equals = 8;
         */
        this.numericLessThanEquals = [];
        /**
         * @generated from field: repeated iam.v1.types.Int32Condition numeric_greater_than_equals = 9;
         */
        this.numericGreaterThanEquals = [];
        /**
         * @generated from field: repeated iam.v1.types.BoolCondition bool = 10;
         */
        this.bool = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new Conditions().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Conditions().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Conditions().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Conditions, a, b);
    }
}
Conditions.runtime = proto3;
Conditions.typeName = "iam.v1.types.Conditions";
Conditions.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "string_equals", kind: "message", T: StringCondition, repeated: true },
    { no: 2, name: "string_not_equals", kind: "message", T: StringCondition, repeated: true },
    { no: 3, name: "string_like", kind: "message", T: StringCondition, repeated: true },
    { no: 4, name: "numeric_equals", kind: "message", T: Int32Condition, repeated: true },
    { no: 5, name: "numeric_not_equals", kind: "message", T: Int32Condition, repeated: true },
    { no: 6, name: "numeric_less_than", kind: "message", T: Int32Condition, repeated: true },
    { no: 7, name: "numeric_greater_than", kind: "message", T: Int32Condition, repeated: true },
    { no: 8, name: "numeric_less_than_equals", kind: "message", T: Int32Condition, repeated: true },
    { no: 9, name: "numeric_greater_than_equals", kind: "message", T: Int32Condition, repeated: true },
    { no: 10, name: "bool", kind: "message", T: BoolCondition, repeated: true },
]);
/**
 * Defines a policy statement.
 *
 * @generated from message iam.v1.types.Statement
 */
export class Statement extends Message {
    constructor(data) {
        super();
        /**
         * Statement ID
         *
         * @generated from field: string name = 1;
         */
        this.name = "";
        /**
         * @generated from field: string effect = 2;
         */
        this.effect = "";
        /**
         * @generated from field: repeated string actions = 3;
         */
        this.actions = [];
        /**
         * @generated from field: repeated string not_actions = 4;
         */
        this.notActions = [];
        /**
         * @generated from field: repeated string resources = 5;
         */
        this.resources = [];
        /**
         * @generated from field: repeated string not_resources = 6;
         */
        this.notResources = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new Statement().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Statement().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Statement().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Statement, a, b);
    }
}
Statement.runtime = proto3;
Statement.typeName = "iam.v1.types.Statement";
Statement.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "effect", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "actions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "not_actions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "resources", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "not_resources", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "conditions", kind: "message", T: Conditions },
]);
/**
 * Represents the overall policy.
 *
 * @generated from message iam.v1.types.Policy
 */
export class Policy extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string dri = 1;
         */
        this.dri = "";
        /**
         * @generated from field: string policy_version = 2;
         */
        this.policyVersion = "";
        /**
         * @generated from field: string policy_name = 3;
         */
        this.policyName = "";
        /**
         * @generated from field: string description = 4;
         */
        this.description = "";
        /**
         * @generated from field: repeated string scopes = 5;
         */
        this.scopes = [];
        /**
         * @generated from field: repeated iam.v1.types.Statement statements = 6;
         */
        this.statements = [];
        /**
         * @generated from field: string created_at = 7;
         */
        this.createdAt = "";
        /**
         * @generated from field: string updated_at = 8;
         */
        this.updatedAt = "";
        /**
         * @generated from field: map<string, string> metadata = 9;
         */
        this.metadata = {};
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new Policy().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Policy().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Policy().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Policy, a, b);
    }
}
Policy.runtime = proto3;
Policy.typeName = "iam.v1.types.Policy";
Policy.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "policy_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "policy_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "scopes", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "statements", kind: "message", T: Statement, repeated: true },
    { no: 7, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: { kind: "scalar", T: 9 /* ScalarType.STRING */ } },
]);
