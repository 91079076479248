var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Loader2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
// Import your utility functions and constants
import { isRedirectBackActionSet, removeRedirectionBackActionForLogin, } from "@defense-station/auth";
import { delay } from "../utils/custom-functions";
import { ssoClient } from "@/grpc/client";
const HandleSSO = () => {
    const params = useMemo(() => new URLSearchParams(window.location.search), []);
    const navigate = useNavigate();
    const turnstile = useRef();
    const [isLoading, setLoading] = useState(true);
    const [isLinkBroken, setLinkBroken] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['authState']);
    const handleLogin = (token) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield ssoClient.sSOCallBack({
                code: params.get("code") || '',
                state: params.get("state") || '',
            });
            removeCookie("authState");
            if (isRedirectBackActionSet()) {
                removeRedirectionBackActionForLogin();
                navigate(-1);
                return;
            }
            yield delay(300);
            window.location.href = "/employee-station";
        }
        catch (e) {
            console.error(e);
            setLoading(false);
            setServerError(true);
        }
    });
    useEffect(() => {
        const state = params.get("state");
        const code = params.get("code");
        if (!state || !code) {
            setLoading(false);
            setLinkBroken(true);
        }
        else {
            setTimeout(() => handleLogin(), 400);
        }
    }, [params]);
    if (isLoading) {
        return (_jsx("div", { className: "h-screen flex items-center justify-center", children: _jsx(Loader2, { className: "h-8 w-8 animate-spin" }) }));
    }
    if (isLinkBroken) {
        return (_jsx("div", { className: "h-[95vh] flex items-center justify-center", children: _jsx(Card, { className: "w-[400px]", children: _jsx(CardContent, { className: "pt-6 text-center", children: _jsx("h2", { className: "text-2xl font-bold mb-4", children: "Link Expired." }) }) }) }));
    }
    if (serverError) {
        return (_jsx("div", { className: "h-[95vh] flex items-center justify-center", children: _jsx(Card, { className: "w-[400px]", children: _jsxs(CardContent, { className: "pt-6 text-center", children: [_jsx("h2", { className: "text-2xl font-bold mb-4", children: "Something went wrong. Please try after sometime." }), _jsx(Button, { asChild: true, children: _jsx(Link, { to: "/login", children: "Go to Login" }) })] }) }) }));
    }
    return _jsx("div", { children: "HandleSSO" });
};
export default HandleSSO;
