import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
const StationNameInput = ({ onSubmit, onChange, isSubmitting = false, defaultValue = "Company-Station-Name" }) => {
    const inputRef = useRef(null);
    const handlePaste = (e) => {
        e.preventDefault();
        const text = e.clipboardData.getData("text/plain");
        document.execCommand("inserttext", false, text);
    };
    const handleClick = (e) => {
        const target = e.target;
        if (target.textContent === defaultValue) {
            target.innerHTML = "";
            target.click();
        }
    };
    const handleBlur = (e) => {
        var _a;
        const target = e.target;
        if (((_a = target.textContent) === null || _a === void 0 ? void 0 : _a.trim()) === "") {
            target.innerHTML = defaultValue;
        }
    };
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            if (!isSubmitting && onSubmit) {
                onSubmit();
            }
        }
    };
    const handleInput = (e) => {
        var _a;
        const target = e.target;
        if (((_a = target.textContent) === null || _a === void 0 ? void 0 : _a.length) === 0) {
            target.innerHTML = defaultValue;
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(target.textContent || "");
    };
    // Ensure cursor is placed at the start when clicking the suffix
    const handleSuffixClick = () => {
        if (inputRef.current) {
            inputRef.current.focus();
            // Place cursor at the end of the content
            const range = document.createRange();
            const selection = window.getSelection();
            range.selectNodeContents(inputRef.current);
            range.collapse(false);
            selection === null || selection === void 0 ? void 0 : selection.removeAllRanges();
            selection === null || selection === void 0 ? void 0 : selection.addRange(range);
        }
    };
    return (_jsxs("div", { className: "relative flex flex-row py-2 px-3 text-sm rounded-lg  border whitespace-nowrap items-center hover:border-primary/80 transition-colors", children: [_jsx("div", { ref: inputRef, contentEditable: true, suppressContentEditableWarning: true, className: "min-w-[2px] outline-none overflow-hidden focus:outline-none", onPaste: handlePaste, onClick: handleClick, onBlur: handleBlur, onKeyDown: handleKeyDown, onInput: handleInput, children: defaultValue }), _jsx("div", { className: "font-bold cursor-pointer select-none text-primary", onClick: handleSuffixClick, children: ".ds.plus" })] }));
};
export default StationNameInput;
