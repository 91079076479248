import { useState } from 'react';
const useSteps = (initialStep = 0, totalSteps = 1) => {
    const [currentStep, setCurrentStep] = useState(initialStep);
    const nextStep = () => {
        setCurrentStep(prevStep => (prevStep < totalSteps - 1 ? prevStep + 1 : prevStep));
    };
    const prevStep = () => {
        setCurrentStep(prevStep => (prevStep > 0 ? prevStep - 1 : prevStep));
    };
    const resetSteps = () => {
        setCurrentStep(initialStep);
    };
    const goToStep = (step) => {
        if (step >= 0 && step < totalSteps) {
            setCurrentStep(step);
        }
    };
    return {
        currentStep,
        isFirstStep: currentStep === 0,
        isLastStep: currentStep === totalSteps - 1,
        nextStep,
        prevStep,
        resetSteps,
        goToStep,
    };
};
export default useSteps;
