// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/iam/v1/service/group/group.proto (package iam.v1.group, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { Group, GroupType, Policy, User } from "../../types/types_pb";
/**
 * @generated from message iam.v1.group.CreateGroupRequest
 */
export class CreateGroupRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string group_name = 1;
         */
        this.groupName = "";
        /**
         * @generated from field: string description = 2;
         */
        this.description = "";
        /**
         * @generated from field: iam.v1.types.GroupType group_type = 3;
         */
        this.groupType = GroupType.UNSPECIFIED;
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new CreateGroupRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new CreateGroupRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new CreateGroupRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(CreateGroupRequest, a, b);
    }
}
CreateGroupRequest.runtime = proto3;
CreateGroupRequest.typeName = "iam.v1.group.CreateGroupRequest";
CreateGroupRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "group_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "group_type", kind: "enum", T: proto3.getEnumType(GroupType) },
]);
/**
 * @generated from message iam.v1.group.CreateGroupResponse
 */
export class CreateGroupResponse extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new CreateGroupResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new CreateGroupResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new CreateGroupResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(CreateGroupResponse, a, b);
    }
}
CreateGroupResponse.runtime = proto3;
CreateGroupResponse.typeName = "iam.v1.group.CreateGroupResponse";
CreateGroupResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "group", kind: "message", T: Group },
]);
/**
 * @generated from message iam.v1.group.GetGroupRequest
 */
export class GetGroupRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string group_dri = 1;
         */
        this.groupDri = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new GetGroupRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GetGroupRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GetGroupRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(GetGroupRequest, a, b);
    }
}
GetGroupRequest.runtime = proto3;
GetGroupRequest.typeName = "iam.v1.group.GetGroupRequest";
GetGroupRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "group_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
/**
 * @generated from message iam.v1.group.GetGroupResponse
 */
export class GetGroupResponse extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new GetGroupResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GetGroupResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GetGroupResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(GetGroupResponse, a, b);
    }
}
GetGroupResponse.runtime = proto3;
GetGroupResponse.typeName = "iam.v1.group.GetGroupResponse";
GetGroupResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "group", kind: "message", T: Group },
]);
/**
 * @generated from message iam.v1.group.ListGroupsRequest
 */
export class ListGroupsRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string group_dri = 1;
         */
        this.groupDri = "";
        /**
         * @generated from field: int32 page_size = 2;
         */
        this.pageSize = 0;
        /**
         * @generated from field: int32 page_number = 3;
         */
        this.pageNumber = 0;
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new ListGroupsRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ListGroupsRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ListGroupsRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(ListGroupsRequest, a, b);
    }
}
ListGroupsRequest.runtime = proto3;
ListGroupsRequest.typeName = "iam.v1.group.ListGroupsRequest";
ListGroupsRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "group_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
]);
/**
 * @generated from message iam.v1.group.ListGroupsResponse
 */
export class ListGroupsResponse extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: repeated iam.v1.types.Group groups = 1;
         */
        this.groups = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new ListGroupsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ListGroupsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ListGroupsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(ListGroupsResponse, a, b);
    }
}
ListGroupsResponse.runtime = proto3;
ListGroupsResponse.typeName = "iam.v1.group.ListGroupsResponse";
ListGroupsResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "groups", kind: "message", T: Group, repeated: true },
]);
/**
 * @generated from message iam.v1.group.UpdateGroupRequest
 */
export class UpdateGroupRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string group_dri = 1;
         */
        this.groupDri = "";
        /**
         * @generated from field: string description = 2;
         */
        this.description = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new UpdateGroupRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UpdateGroupRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UpdateGroupRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(UpdateGroupRequest, a, b);
    }
}
UpdateGroupRequest.runtime = proto3;
UpdateGroupRequest.typeName = "iam.v1.group.UpdateGroupRequest";
UpdateGroupRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "group_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
/**
 * @generated from message iam.v1.group.UpdateGroupResponse
 */
export class UpdateGroupResponse extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new UpdateGroupResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UpdateGroupResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UpdateGroupResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(UpdateGroupResponse, a, b);
    }
}
UpdateGroupResponse.runtime = proto3;
UpdateGroupResponse.typeName = "iam.v1.group.UpdateGroupResponse";
UpdateGroupResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "group", kind: "message", T: Group },
]);
/**
 * @generated from message iam.v1.group.DeleteGroupsRequest
 */
export class DeleteGroupsRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: repeated string group_dris = 1;
         */
        this.groupDris = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new DeleteGroupsRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DeleteGroupsRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DeleteGroupsRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(DeleteGroupsRequest, a, b);
    }
}
DeleteGroupsRequest.runtime = proto3;
DeleteGroupsRequest.typeName = "iam.v1.group.DeleteGroupsRequest";
DeleteGroupsRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "group_dris", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
]);
/**
 * @generated from message iam.v1.group.AddPoliciesToGroupsRequest
 */
export class AddPoliciesToGroupsRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: repeated string group_dris = 1;
         */
        this.groupDris = [];
        /**
         * @generated from field: repeated string policy_dris = 2;
         */
        this.policyDris = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new AddPoliciesToGroupsRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AddPoliciesToGroupsRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AddPoliciesToGroupsRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(AddPoliciesToGroupsRequest, a, b);
    }
}
AddPoliciesToGroupsRequest.runtime = proto3;
AddPoliciesToGroupsRequest.typeName = "iam.v1.group.AddPoliciesToGroupsRequest";
AddPoliciesToGroupsRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "group_dris", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "policy_dris", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
]);
/**
 * @generated from message iam.v1.group.RemovePoliciesFromGroupsRequest
 */
export class RemovePoliciesFromGroupsRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: repeated string group_dris = 1;
         */
        this.groupDris = [];
        /**
         * @generated from field: repeated string policy_dris = 2;
         */
        this.policyDris = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new RemovePoliciesFromGroupsRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new RemovePoliciesFromGroupsRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new RemovePoliciesFromGroupsRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(RemovePoliciesFromGroupsRequest, a, b);
    }
}
RemovePoliciesFromGroupsRequest.runtime = proto3;
RemovePoliciesFromGroupsRequest.typeName = "iam.v1.group.RemovePoliciesFromGroupsRequest";
RemovePoliciesFromGroupsRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "group_dris", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "policy_dris", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
]);
/**
 * @generated from message iam.v1.group.AddUsersToGroupsRequest
 */
export class AddUsersToGroupsRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: repeated string group_dris = 1;
         */
        this.groupDris = [];
        /**
         * @generated from field: repeated string user_dris = 2;
         */
        this.userDris = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new AddUsersToGroupsRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AddUsersToGroupsRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AddUsersToGroupsRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(AddUsersToGroupsRequest, a, b);
    }
}
AddUsersToGroupsRequest.runtime = proto3;
AddUsersToGroupsRequest.typeName = "iam.v1.group.AddUsersToGroupsRequest";
AddUsersToGroupsRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "group_dris", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "user_dris", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
]);
/**
 * @generated from message iam.v1.group.RemoveUsersFromGroupsRequest
 */
export class RemoveUsersFromGroupsRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: repeated string group_dris = 1;
         */
        this.groupDris = [];
        /**
         * @generated from field: repeated string user_dris = 2;
         */
        this.userDris = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new RemoveUsersFromGroupsRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new RemoveUsersFromGroupsRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new RemoveUsersFromGroupsRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(RemoveUsersFromGroupsRequest, a, b);
    }
}
RemoveUsersFromGroupsRequest.runtime = proto3;
RemoveUsersFromGroupsRequest.typeName = "iam.v1.group.RemoveUsersFromGroupsRequest";
RemoveUsersFromGroupsRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "group_dris", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "user_dris", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
]);
/**
 * @generated from message iam.v1.group.ListGroupPoliciesRequest
 */
export class ListGroupPoliciesRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string group_dri = 1;
         */
        this.groupDri = "";
        /**
         * @generated from field: string search = 2;
         */
        this.search = "";
        /**
         * @generated from field: int32 page_size = 3;
         */
        this.pageSize = 0;
        /**
         * @generated from field: int32 page_number = 4;
         */
        this.pageNumber = 0;
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new ListGroupPoliciesRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ListGroupPoliciesRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ListGroupPoliciesRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(ListGroupPoliciesRequest, a, b);
    }
}
ListGroupPoliciesRequest.runtime = proto3;
ListGroupPoliciesRequest.typeName = "iam.v1.group.ListGroupPoliciesRequest";
ListGroupPoliciesRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "group_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "search", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
]);
/**
 * @generated from message iam.v1.group.ListGroupPoliciesResponse
 */
export class ListGroupPoliciesResponse extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: repeated iam.v1.types.Policy policies = 1;
         */
        this.policies = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new ListGroupPoliciesResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ListGroupPoliciesResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ListGroupPoliciesResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(ListGroupPoliciesResponse, a, b);
    }
}
ListGroupPoliciesResponse.runtime = proto3;
ListGroupPoliciesResponse.typeName = "iam.v1.group.ListGroupPoliciesResponse";
ListGroupPoliciesResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "policies", kind: "message", T: Policy, repeated: true },
]);
/**
 * @generated from message iam.v1.group.ListGroupUsersRequest
 */
export class ListGroupUsersRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string group_dri = 1;
         */
        this.groupDri = "";
        /**
         * @generated from field: string search = 2;
         */
        this.search = "";
        /**
         * @generated from field: int32 page_size = 3;
         */
        this.pageSize = 0;
        /**
         * @generated from field: int32 page_number = 4;
         */
        this.pageNumber = 0;
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new ListGroupUsersRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ListGroupUsersRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ListGroupUsersRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(ListGroupUsersRequest, a, b);
    }
}
ListGroupUsersRequest.runtime = proto3;
ListGroupUsersRequest.typeName = "iam.v1.group.ListGroupUsersRequest";
ListGroupUsersRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "group_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "search", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
]);
/**
 * @generated from message iam.v1.group.ListGroupUsersResponse
 */
export class ListGroupUsersResponse extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: repeated iam.v1.types.User users = 1;
         */
        this.users = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new ListGroupUsersResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ListGroupUsersResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ListGroupUsersResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(ListGroupUsersResponse, a, b);
    }
}
ListGroupUsersResponse.runtime = proto3;
ListGroupUsersResponse.typeName = "iam.v1.group.ListGroupUsersResponse";
ListGroupUsersResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "users", kind: "message", T: User, repeated: true },
]);
