// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts,import_extension=none"
// @generated from file station/iam/v1/service/user/user.proto (package iam.v1.user, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { AcceptInviteRequest, AcceptInviteResponse, CreateRootUserRequest, CreateRootUserResponse, CreateUserRequest, CreateUserResponse, DeleteUsersRequest, GetAuthInfoRequest, GetAuthInfoResponse, GetAuthTokenRequest, GetAuthTokenResponse, GetUserRequest, GetUserResponse, ListUserGroupsRequest, ListUserGroupsResponse, ListUserPoliciesRequest, ListUserPoliciesResponse, ListUsersRequest, ListUsersResponse, RequestOTPRequest, RequestOTPResponse, UpdateUserRequest, UpdateUserResponse } from "./user_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";
/**
 * @generated from service iam.v1.user.UserService
 */
export const UserService = {
    typeName: "iam.v1.user.UserService",
    methods: {
        /**
         * @generated from rpc iam.v1.user.UserService.CreateRootUser
         */
        createRootUser: {
            name: "CreateRootUser",
            I: CreateRootUserRequest,
            O: CreateRootUserResponse,
            kind: MethodKind.Unary,
        },
        /**
         * @generated from rpc iam.v1.user.UserService.CreateUser
         */
        createUser: {
            name: "CreateUser",
            I: CreateUserRequest,
            O: CreateUserResponse,
            kind: MethodKind.Unary,
        },
        /**
         * @generated from rpc iam.v1.user.UserService.GetUser
         */
        getUser: {
            name: "GetUser",
            I: GetUserRequest,
            O: GetUserResponse,
            kind: MethodKind.Unary,
        },
        /**
         * @generated from rpc iam.v1.user.UserService.ListUsers
         */
        listUsers: {
            name: "ListUsers",
            I: ListUsersRequest,
            O: ListUsersResponse,
            kind: MethodKind.Unary,
        },
        /**
         * @generated from rpc iam.v1.user.UserService.UpdateUser
         */
        updateUser: {
            name: "UpdateUser",
            I: UpdateUserRequest,
            O: UpdateUserResponse,
            kind: MethodKind.Unary,
        },
        /**
         * @generated from rpc iam.v1.user.UserService.DeleteUsers
         */
        deleteUsers: {
            name: "DeleteUsers",
            I: DeleteUsersRequest,
            O: Empty,
            kind: MethodKind.Unary,
        },
        /**
         * @generated from rpc iam.v1.user.UserService.RequestOTP
         */
        requestOTP: {
            name: "RequestOTP",
            I: RequestOTPRequest,
            O: RequestOTPResponse,
            kind: MethodKind.Unary,
        },
        /**
         * @generated from rpc iam.v1.user.UserService.GetAuthToken
         */
        getAuthToken: {
            name: "GetAuthToken",
            I: GetAuthTokenRequest,
            O: GetAuthTokenResponse,
            kind: MethodKind.Unary,
        },
        /**
         * @generated from rpc iam.v1.user.UserService.GetAuthInfo
         */
        getAuthInfo: {
            name: "GetAuthInfo",
            I: GetAuthInfoRequest,
            O: GetAuthInfoResponse,
            kind: MethodKind.Unary,
        },
        /**
         * @generated from rpc iam.v1.user.UserService.AcceptInvite
         */
        acceptInvite: {
            name: "AcceptInvite",
            I: AcceptInviteRequest,
            O: AcceptInviteResponse,
            kind: MethodKind.Unary,
        },
        /**
         * @generated from rpc iam.v1.user.UserService.ListUserPolicies
         */
        listUserPolicies: {
            name: "ListUserPolicies",
            I: ListUserPoliciesRequest,
            O: ListUserPoliciesResponse,
            kind: MethodKind.Unary,
        },
        /**
         * @generated from rpc iam.v1.user.UserService.ListUserGroups
         */
        listUserGroups: {
            name: "ListUserGroups",
            I: ListUserGroupsRequest,
            O: ListUserGroupsResponse,
            kind: MethodKind.Unary,
        },
    }
};
