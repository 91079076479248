import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, } from "react-router-dom";
import "./css/main.css";
import Suspense from "./pages/Suspense";
import NotFound from "./pages/NotFound";
import { captchaKey } from "./constants/api-keys";
import DecideStation from "./pages/DecideStation";
import { CookiesProvider } from "react-cookie";
import "./style.css";
import HandleSSO from "./pages/HandleSSO";
import { Toaster } from "@/components/ui/toaster";
export default function Root() {
    useEffect(() => {
        const script = document.createElement("script");
        script.src =
            `https://www.google.com/recaptcha/api.js?render=${captchaKey}`;
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return (_jsxs("div", { className: "fixed w-screen h-screen overflow-auto", children: [_jsx(CookiesProvider, { defaultSetOptions: { path: '/' }, children: _jsx(Router, { basename: "/", children: _jsx(React.Suspense, { fallback: _jsx(Suspense, {}), children: _jsxs(Routes, { children: [_jsx(Route, { index: true, path: "/", element: _jsx(DecideStation, {}) }), _jsx(Route, { index: true, path: "/signin", element: _jsx(DecideStation, {}) }), _jsx(Route, { index: true, path: "/auth/sso/callback", element: _jsx(HandleSSO, {}) }), _jsx(Route, { path: "*", element: _jsx(NotFound, {}) })] }) }) }) }), _jsx(Toaster, {})] }));
}
