// At the top of the file
import { createPromiseClient } from "@connectrpc/connect";
import { createAuthorizedTransport } from "../../../../../src/transport";
import { UsageService } from "./usage_connect";
/**
 * Factory function to create a 'usage' client.
 *
 * @param {string} baseUrl - The base URL for 'usage'.
 * @param {Array<Function>} additionalInterceptors - Optional additional interceptors.
 * @returns {PromiseClient} The configured 'usage' client.
 */
export const createUsageServiceClient = (config, credentialProvider, additionalInterceptors = []) => {
    const transport = createAuthorizedTransport(config, credentialProvider, additionalInterceptors);
    return createPromiseClient(UsageService, transport);
};
