var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { cn } from "src/lib/utils";
export const Spinner = (_a) => {
    var { className, size = 24 } = _a, props = __rest(_a, ["className", "size"]);
    return (_jsx("svg", Object.assign({ height: size, width: size, xmlns: "http://www.w3.org/2000/svg" }, props, { className: cn("animate-spin", className), fill: "none", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2", viewBox: "0 0 24 24", children: _jsx("path", { d: "M21 12a9 9 0 1 1-6.219-8.56" }) })));
};
