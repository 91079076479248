// At the top of the file
import { createPromiseClient } from "@connectrpc/connect";
import { createAuthorizedTransport } from "../../../../../src/transport";
import { SSOService } from "./sso_connect";
/**
 * Factory function to create a 'sso' client.
 *
 * @param {string} baseUrl - The base URL for 'sso'.
 * @param {Array<Function>} additionalInterceptors - Optional additional interceptors.
 * @returns {PromiseClient} The configured 'sso' client.
 */
export const createSsoServiceClient = (config, credentialProvider, additionalInterceptors = []) => {
    const transport = createAuthorizedTransport(config, credentialProvider, additionalInterceptors);
    return createPromiseClient(SSOService, transport);
};
