import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, CardContent } from '@/components/ui/card';
import { Player } from '@lottiefiles/react-lottie-player';
import jsonPlayerData from '../assets/not-found.json';
import { NavLink } from 'react-router-dom';
const NotFound = () => {
    const playerSize = {
        base: '100%',
        md: '700px',
        xl: '800px'
    };
    return (_jsxs("div", { className: "min-h-[90vh] flex flex-col items-center justify-center", children: [_jsx(Card, { className: "bg-transparent border-none shadow-none", children: _jsx(CardContent, { className: "p-0", children: _jsx(Player, { autoplay: true, loop: true, keepLastFrame: true, src: jsonPlayerData, className: `
              h-full w-full
              md:h-[700px] md:w-[700px]
              xl:h-[800px] xl:w-[800px]
            ` }) }) }), _jsxs("div", { className: "flex flex-col md:flex-row items-center mt-5 gap-1 md:gap-2", children: [_jsx("p", { className: "text-slate-900 dark:text-white font-medium text-base md:text-lg", children: "Maybe it's best you start back at our home page..." }), _jsx(NavLink, { to: "/", className: "text-primary hover:text-primary/90 font-medium text-base md:text-lg", children: "Return at home here." })] })] }));
};
export default NotFound;
