// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/iam/v1/service/access_key/access_key.proto (package iam.v1.key, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { AccessKey } from "../../types/types_pb";
/**
 * @generated from message iam.v1.key.CreateAccessKeyRequest
 */
export class CreateAccessKeyRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string user_dri = 1;
         */
        this.userDri = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new CreateAccessKeyRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new CreateAccessKeyRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new CreateAccessKeyRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(CreateAccessKeyRequest, a, b);
    }
}
CreateAccessKeyRequest.runtime = proto3;
CreateAccessKeyRequest.typeName = "iam.v1.key.CreateAccessKeyRequest";
CreateAccessKeyRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "user_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
/**
 * @generated from message iam.v1.key.CreateAccessKeyResponse
 */
export class CreateAccessKeyResponse extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new CreateAccessKeyResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new CreateAccessKeyResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new CreateAccessKeyResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(CreateAccessKeyResponse, a, b);
    }
}
CreateAccessKeyResponse.runtime = proto3;
CreateAccessKeyResponse.typeName = "iam.v1.key.CreateAccessKeyResponse";
CreateAccessKeyResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "access_key", kind: "message", T: AccessKey },
]);
/**
 * @generated from message iam.v1.key.GetAccessKeyRequest
 */
export class GetAccessKeyRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string access_key_dri = 1;
         */
        this.accessKeyDri = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new GetAccessKeyRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GetAccessKeyRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GetAccessKeyRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(GetAccessKeyRequest, a, b);
    }
}
GetAccessKeyRequest.runtime = proto3;
GetAccessKeyRequest.typeName = "iam.v1.key.GetAccessKeyRequest";
GetAccessKeyRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "access_key_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
/**
 * @generated from message iam.v1.key.GetAccessKeyResponse
 */
export class GetAccessKeyResponse extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new GetAccessKeyResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GetAccessKeyResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GetAccessKeyResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(GetAccessKeyResponse, a, b);
    }
}
GetAccessKeyResponse.runtime = proto3;
GetAccessKeyResponse.typeName = "iam.v1.key.GetAccessKeyResponse";
GetAccessKeyResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "access_key", kind: "message", T: AccessKey },
]);
/**
 * @generated from message iam.v1.key.ListAccessKeysRequest
 */
export class ListAccessKeysRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string user_dri = 1;
         */
        this.userDri = "";
        /**
         * @generated from field: int32 page_size = 2;
         */
        this.pageSize = 0;
        /**
         * @generated from field: int32 page_number = 3;
         */
        this.pageNumber = 0;
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new ListAccessKeysRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ListAccessKeysRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ListAccessKeysRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(ListAccessKeysRequest, a, b);
    }
}
ListAccessKeysRequest.runtime = proto3;
ListAccessKeysRequest.typeName = "iam.v1.key.ListAccessKeysRequest";
ListAccessKeysRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "user_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
]);
/**
 * @generated from message iam.v1.key.ListAccessKeysResponse
 */
export class ListAccessKeysResponse extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: repeated iam.v1.types.AccessKey access_keys = 1;
         */
        this.accessKeys = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new ListAccessKeysResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ListAccessKeysResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ListAccessKeysResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(ListAccessKeysResponse, a, b);
    }
}
ListAccessKeysResponse.runtime = proto3;
ListAccessKeysResponse.typeName = "iam.v1.key.ListAccessKeysResponse";
ListAccessKeysResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "access_keys", kind: "message", T: AccessKey, repeated: true },
]);
/**
 * @generated from message iam.v1.key.UpdateAccessKeyRequest
 */
export class UpdateAccessKeyRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string access_key_dri = 1;
         */
        this.accessKeyDri = "";
        /**
         * @generated from field: bool active = 2;
         */
        this.active = false;
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new UpdateAccessKeyRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UpdateAccessKeyRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UpdateAccessKeyRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(UpdateAccessKeyRequest, a, b);
    }
}
UpdateAccessKeyRequest.runtime = proto3;
UpdateAccessKeyRequest.typeName = "iam.v1.key.UpdateAccessKeyRequest";
UpdateAccessKeyRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "access_key_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
]);
/**
 * @generated from message iam.v1.key.UpdateAccessKeyResponse
 */
export class UpdateAccessKeyResponse extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new UpdateAccessKeyResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UpdateAccessKeyResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UpdateAccessKeyResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(UpdateAccessKeyResponse, a, b);
    }
}
UpdateAccessKeyResponse.runtime = proto3;
UpdateAccessKeyResponse.typeName = "iam.v1.key.UpdateAccessKeyResponse";
UpdateAccessKeyResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "access_key", kind: "message", T: AccessKey },
]);
/**
 * @generated from message iam.v1.key.DeleteAccessKeyRequest
 */
export class DeleteAccessKeyRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string access_key_dri = 1;
         */
        this.accessKeyDri = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new DeleteAccessKeyRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DeleteAccessKeyRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DeleteAccessKeyRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(DeleteAccessKeyRequest, a, b);
    }
}
DeleteAccessKeyRequest.runtime = proto3;
DeleteAccessKeyRequest.typeName = "iam.v1.key.DeleteAccessKeyRequest";
DeleteAccessKeyRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "access_key_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
