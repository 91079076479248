// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts,import_extension=none"
// @generated from file station/iam/v1/service/alias/alias.proto (package iam.v1.alias, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { CreateAliasRequest, CreateAliasResponse, DeleteAliasRequest, GetAliasRequest, GetAliasResponse, UpdateAliasRequest, UpdateAliasResponse } from "./alias_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";
/**
 * @generated from service iam.v1.alias.AliasService
 */
export const AliasService = {
    typeName: "iam.v1.alias.AliasService",
    methods: {
        /**
         * @generated from rpc iam.v1.alias.AliasService.CreateAlias
         */
        createAlias: {
            name: "CreateAlias",
            I: CreateAliasRequest,
            O: CreateAliasResponse,
            kind: MethodKind.Unary,
        },
        /**
         * @generated from rpc iam.v1.alias.AliasService.GetAlias
         */
        getAlias: {
            name: "GetAlias",
            I: GetAliasRequest,
            O: GetAliasResponse,
            kind: MethodKind.Unary,
        },
        /**
         * @generated from rpc iam.v1.alias.AliasService.UpdateAlias
         */
        updateAlias: {
            name: "UpdateAlias",
            I: UpdateAliasRequest,
            O: UpdateAliasResponse,
            kind: MethodKind.Unary,
        },
        /**
         * @generated from rpc iam.v1.alias.AliasService.DeleteAlias
         */
        deleteAlias: {
            name: "DeleteAlias",
            I: DeleteAliasRequest,
            O: Empty,
            kind: MethodKind.Unary,
        },
    }
};
