// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/iam/v1/service/alias/alias.proto (package iam.v1.alias, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { Alias } from "../../types/types_pb";
/**
 * @generated from message iam.v1.alias.CreateAliasRequest
 */
export class CreateAliasRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string alias = 1;
         */
        this.alias = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new CreateAliasRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new CreateAliasRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new CreateAliasRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(CreateAliasRequest, a, b);
    }
}
CreateAliasRequest.runtime = proto3;
CreateAliasRequest.typeName = "iam.v1.alias.CreateAliasRequest";
CreateAliasRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "alias", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
/**
 * @generated from message iam.v1.alias.CreateAliasResponse
 */
export class CreateAliasResponse extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new CreateAliasResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new CreateAliasResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new CreateAliasResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(CreateAliasResponse, a, b);
    }
}
CreateAliasResponse.runtime = proto3;
CreateAliasResponse.typeName = "iam.v1.alias.CreateAliasResponse";
CreateAliasResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "alias", kind: "message", T: Alias },
]);
/**
 * @generated from message iam.v1.alias.GetAliasRequest
 */
export class GetAliasRequest extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new GetAliasRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GetAliasRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GetAliasRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(GetAliasRequest, a, b);
    }
}
GetAliasRequest.runtime = proto3;
GetAliasRequest.typeName = "iam.v1.alias.GetAliasRequest";
GetAliasRequest.fields = proto3.util.newFieldList(() => []);
/**
 * @generated from message iam.v1.alias.GetAliasResponse
 */
export class GetAliasResponse extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new GetAliasResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GetAliasResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GetAliasResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(GetAliasResponse, a, b);
    }
}
GetAliasResponse.runtime = proto3;
GetAliasResponse.typeName = "iam.v1.alias.GetAliasResponse";
GetAliasResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "alias", kind: "message", T: Alias },
]);
/**
 * @generated from message iam.v1.alias.UpdateAliasRequest
 */
export class UpdateAliasRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string alias = 1;
         */
        this.alias = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new UpdateAliasRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UpdateAliasRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UpdateAliasRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(UpdateAliasRequest, a, b);
    }
}
UpdateAliasRequest.runtime = proto3;
UpdateAliasRequest.typeName = "iam.v1.alias.UpdateAliasRequest";
UpdateAliasRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "alias", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
/**
 * @generated from message iam.v1.alias.UpdateAliasResponse
 */
export class UpdateAliasResponse extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new UpdateAliasResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UpdateAliasResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UpdateAliasResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(UpdateAliasResponse, a, b);
    }
}
UpdateAliasResponse.runtime = proto3;
UpdateAliasResponse.typeName = "iam.v1.alias.UpdateAliasResponse";
UpdateAliasResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "alias", kind: "message", T: Alias },
]);
/**
 * @generated from message iam.v1.alias.DeleteAliasRequest
 */
export class DeleteAliasRequest extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new DeleteAliasRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DeleteAliasRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DeleteAliasRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(DeleteAliasRequest, a, b);
    }
}
DeleteAliasRequest.runtime = proto3;
DeleteAliasRequest.typeName = "iam.v1.alias.DeleteAliasRequest";
DeleteAliasRequest.fields = proto3.util.newFieldList(() => []);
