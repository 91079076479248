// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/iam/v1/service/policy/policy.proto (package iam.v1.policy, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { Policy, Statement } from "../../types/types_pb";
/**
 * @generated from message iam.v1.policy.CreatePolicyRequest
 */
export class CreatePolicyRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string policy_version = 1;
         */
        this.policyVersion = "";
        /**
         * @generated from field: string policy_name = 2;
         */
        this.policyName = "";
        /**
         * @generated from field: string description = 3;
         */
        this.description = "";
        /**
         * @generated from field: repeated iam.v1.types.Statement statements = 4;
         */
        this.statements = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new CreatePolicyRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new CreatePolicyRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new CreatePolicyRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(CreatePolicyRequest, a, b);
    }
}
CreatePolicyRequest.runtime = proto3;
CreatePolicyRequest.typeName = "iam.v1.policy.CreatePolicyRequest";
CreatePolicyRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "policy_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "policy_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "statements", kind: "message", T: Statement, repeated: true },
]);
/**
 * @generated from message iam.v1.policy.CreatePolicyResponse
 */
export class CreatePolicyResponse extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new CreatePolicyResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new CreatePolicyResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new CreatePolicyResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(CreatePolicyResponse, a, b);
    }
}
CreatePolicyResponse.runtime = proto3;
CreatePolicyResponse.typeName = "iam.v1.policy.CreatePolicyResponse";
CreatePolicyResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "policy", kind: "message", T: Policy },
]);
/**
 * @generated from message iam.v1.policy.GetPolicyRequest
 */
export class GetPolicyRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string policy_dri = 1;
         */
        this.policyDri = "";
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new GetPolicyRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GetPolicyRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GetPolicyRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(GetPolicyRequest, a, b);
    }
}
GetPolicyRequest.runtime = proto3;
GetPolicyRequest.typeName = "iam.v1.policy.GetPolicyRequest";
GetPolicyRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "policy_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
]);
/**
 * @generated from message iam.v1.policy.GetPolicyResponse
 */
export class GetPolicyResponse extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new GetPolicyResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GetPolicyResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GetPolicyResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(GetPolicyResponse, a, b);
    }
}
GetPolicyResponse.runtime = proto3;
GetPolicyResponse.typeName = "iam.v1.policy.GetPolicyResponse";
GetPolicyResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "policy", kind: "message", T: Policy },
]);
/**
 * @generated from message iam.v1.policy.ListPoliciesRequest
 */
export class ListPoliciesRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string policy_name = 1;
         */
        this.policyName = "";
        /**
         * @generated from field: bool include_ds_managed = 2;
         */
        this.includeDsManaged = false;
        /**
         * @generated from field: int32 page_size = 3;
         */
        this.pageSize = 0;
        /**
         * @generated from field: int32 page_number = 4;
         */
        this.pageNumber = 0;
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new ListPoliciesRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ListPoliciesRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ListPoliciesRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(ListPoliciesRequest, a, b);
    }
}
ListPoliciesRequest.runtime = proto3;
ListPoliciesRequest.typeName = "iam.v1.policy.ListPoliciesRequest";
ListPoliciesRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "policy_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "include_ds_managed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
]);
/**
 * @generated from message iam.v1.policy.ListPoliciesResponse
 */
export class ListPoliciesResponse extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: repeated iam.v1.types.Policy policies = 1;
         */
        this.policies = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new ListPoliciesResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ListPoliciesResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ListPoliciesResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(ListPoliciesResponse, a, b);
    }
}
ListPoliciesResponse.runtime = proto3;
ListPoliciesResponse.typeName = "iam.v1.policy.ListPoliciesResponse";
ListPoliciesResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "policies", kind: "message", T: Policy, repeated: true },
]);
/**
 * @generated from message iam.v1.policy.UpdatePolicyRequest
 */
export class UpdatePolicyRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string policy_dri = 1;
         */
        this.policyDri = "";
        /**
         * @generated from field: string description = 2;
         */
        this.description = "";
        /**
         * @generated from field: repeated iam.v1.types.Statement statements = 3;
         */
        this.statements = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new UpdatePolicyRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UpdatePolicyRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UpdatePolicyRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(UpdatePolicyRequest, a, b);
    }
}
UpdatePolicyRequest.runtime = proto3;
UpdatePolicyRequest.typeName = "iam.v1.policy.UpdatePolicyRequest";
UpdatePolicyRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "policy_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "statements", kind: "message", T: Statement, repeated: true },
]);
/**
 * @generated from message iam.v1.policy.UpdatePolicyResponse
 */
export class UpdatePolicyResponse extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new UpdatePolicyResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UpdatePolicyResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UpdatePolicyResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(UpdatePolicyResponse, a, b);
    }
}
UpdatePolicyResponse.runtime = proto3;
UpdatePolicyResponse.typeName = "iam.v1.policy.UpdatePolicyResponse";
UpdatePolicyResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "policy", kind: "message", T: Policy },
]);
/**
 * @generated from message iam.v1.policy.DeletePoliciesRequest
 */
export class DeletePoliciesRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: repeated string policy_dris = 1;
         */
        this.policyDris = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new DeletePoliciesRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new DeletePoliciesRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new DeletePoliciesRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(DeletePoliciesRequest, a, b);
    }
}
DeletePoliciesRequest.runtime = proto3;
DeletePoliciesRequest.typeName = "iam.v1.policy.DeletePoliciesRequest";
DeletePoliciesRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "policy_dris", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
]);
/**
 * @generated from message iam.v1.policy.InternalListUserPoliciesRequest
 */
export class InternalListUserPoliciesRequest extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: string account_id = 1;
         */
        this.accountId = "";
        /**
         * @generated from field: string username = 2;
         */
        this.username = "";
        /**
         * @generated from field: int32 page_size = 3;
         */
        this.pageSize = 0;
        /**
         * @generated from field: int32 page_number = 4;
         */
        this.pageNumber = 0;
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new InternalListUserPoliciesRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InternalListUserPoliciesRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InternalListUserPoliciesRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(InternalListUserPoliciesRequest, a, b);
    }
}
InternalListUserPoliciesRequest.runtime = proto3;
InternalListUserPoliciesRequest.typeName = "iam.v1.policy.InternalListUserPoliciesRequest";
InternalListUserPoliciesRequest.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
]);
/**
 * @generated from message iam.v1.policy.InternalListUserPoliciesResponse
 */
export class InternalListUserPoliciesResponse extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: repeated iam.v1.types.Policy policies = 1;
         */
        this.policies = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new InternalListUserPoliciesResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InternalListUserPoliciesResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InternalListUserPoliciesResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(InternalListUserPoliciesResponse, a, b);
    }
}
InternalListUserPoliciesResponse.runtime = proto3;
InternalListUserPoliciesResponse.typeName = "iam.v1.policy.InternalListUserPoliciesResponse";
InternalListUserPoliciesResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "policies", kind: "message", T: Policy, repeated: true },
]);
/**
 * @generated from message iam.v1.policy.GetAlowedPolicyAttributesRequest
 */
export class GetAlowedPolicyAttributesRequest extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new GetAlowedPolicyAttributesRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GetAlowedPolicyAttributesRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GetAlowedPolicyAttributesRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(GetAlowedPolicyAttributesRequest, a, b);
    }
}
GetAlowedPolicyAttributesRequest.runtime = proto3;
GetAlowedPolicyAttributesRequest.typeName = "iam.v1.policy.GetAlowedPolicyAttributesRequest";
GetAlowedPolicyAttributesRequest.fields = proto3.util.newFieldList(() => []);
/**
 * @generated from message iam.v1.policy.GetAlowedPolicyAttributesResponse
 */
export class GetAlowedPolicyAttributesResponse extends Message {
    constructor(data) {
        super();
        /**
         * @generated from field: repeated string resource_types = 1;
         */
        this.resourceTypes = [];
        /**
         * @generated from field: repeated string actions = 2;
         */
        this.actions = [];
        /**
         * @generated from field: repeated string condition_keys = 3;
         */
        this.conditionKeys = [];
        proto3.util.initPartial(data, this);
    }
    static fromBinary(bytes, options) {
        return new GetAlowedPolicyAttributesResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GetAlowedPolicyAttributesResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GetAlowedPolicyAttributesResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(GetAlowedPolicyAttributesResponse, a, b);
    }
}
GetAlowedPolicyAttributesResponse.runtime = proto3;
GetAlowedPolicyAttributesResponse.typeName = "iam.v1.policy.GetAlowedPolicyAttributesResponse";
GetAlowedPolicyAttributesResponse.fields = proto3.util.newFieldList(() => [
    { no: 1, name: "resource_types", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "actions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "condition_keys", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
]);
